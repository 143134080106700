<template>
  <div style="height: 100vh">
    <div style="float: right; margin-top: 8px; margin-right: 8px">
      <a-dropdown>
        <a-button type="link" style="font-size: 16px">
          <a-icon type="global" />
          <span>{{ language }}</span>
          <a-icon type="down" />
        </a-button>
        <a-menu slot="overlay" @click="changeLanguage">
          <a-menu-item key="zh-Hans">
            <span>{{ $t("简体中文") }}</span>
          </a-menu-item>
          <a-menu-item key="en">
            <span>{{ $t("English") }}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <div class="background" ref="card">
      <a-card class="card">
        <div>
          <div style="text-align: center; padding-top: 40px">
            <img
              :src="logoItem.logo_image ? logoItem.logo_image_item.file : logo"
              width="100"
              style="cursor: pointer; margin-top: 16px"
              @click="backIndex"
            />
          </div>

          <div class="title" style="margin-top: 16px">
            <span style="cursor: pointer; font-size: 24px" @click="backIndex">
              {{ logoItem.logo_title && logoItem.logo_title.length > 0 ? logoItem.logo_title : $t("盒木") }}{{ $t("WMS") }}
            </span>
            <span style="font-size: 16px; margin-left: 4px">{{ $t("V2.0") }}</span>
          </div>
          <div style="text-align: center; font-size: 16px; color: #1890ff; margin-top: 8px; margin-bottom: 24px">
            <span>{{ $t("仓库管理系统") }}</span>
          </div>
        </div>
        <router-view />
      </a-card>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "UserLayout",
  data() {
    return {
      logo: require("@/assets/logo.png"),
      language: "简体中文",
      languageDict: { "zh-CN": "zh-Hans", "en-US": "en" },
    };
  },
  computed: {
    logoItem() {
      return this.$store.state.system.logoItem;
    },
  },
  methods: {
    backIndex() {
      window.open("http://www.himool.com", "_self");
    },
    changeLanguage({ key }) {
      Cookies.set("language", key);
      this.$router.go(0);
    },
  },
  mounted() {
    let language = Cookies.get("language");
    if (language === undefined) {
      language = this.languageDict[navigator.language];
      this.changeLanguage({ key: language });
    }

    this.language = language === "zh-Hans" ? "简体中文" : "English";
  },
};
</script>

<style scoped>
.card {
  width: 520px;
  border-radius: 8px;
  padding-bottom: 64px;
  margin: auto;
}

.background {
  min-height: 100%;
  padding: 10vh 0;
  background: #f0f2f5 url("~@/assets/background.svg") no-repeat 50%;
}

.title {
  text-align: center;
  font-size: 32px;
  color: #1890ff;
  margin-top: 8px;
  font-weight: bold;
}
</style>
